import { useState, useEffect } from "react";
import InputMask from "react-input-mask";
import {
  Container,
  Content,
  Title,
  FormContainer,
  Input,
  Button,
  Message,
  Footer,
} from "./styles";

interface AcceptInviteFormProps {
  onSubmit: (cpf: string) => Promise<void>;
}

export function AcceptInviteForm({ onSubmit }: AcceptInviteFormProps) {
  const [cpf, setCpf] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const submitCPF = async () => {
    setLoading(true);
    setMessage("");
    try {
      await onSubmit(cpf);
      setMessage("Token enviado com sucesso.");
    } catch (error: unknown) {
      if (error instanceof Error) {
        setMessage(error.message);
      } else {
        setMessage("Erro ao enviar token.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await submitCPF();
  };

  useEffect(() => {
    const cpfPattern = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
    if (cpfPattern.test(cpf) && !loading && !message) {
      console.log("CPF completo, enviando automaticamente:", cpf);
      submitCPF();
    }
  }, [cpf, loading, message]);

  return (
    <Container>
      <Content>
        <Title>
          IDENTIFIQUE-SE PARA <br />CRIAR SUA SENHA
        </Title>
        <FormContainer onSubmit={handleSubmit}>
          {message && <Message>{message}</Message>}
          <InputMask
            mask="999.999.999-99"
            value={cpf}
            onChange={(e) => setCpf(e.target.value)}
          >
            {(inputProps: any) => (
              <Input type="text" placeholder="CPF" {...inputProps} />
            )}
          </InputMask>
          <Button type="submit" disabled={loading}>
            {loading ? "Enviando..." : "Enviar"}
          </Button>
        </FormContainer>
      </Content>
      <Footer />
    </Container>
  );
}
