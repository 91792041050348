import { HttpClient, HttpStatusCode } from "@/data/protocols/http";
import { InvalidCredentialsErros, UnexpectedError } from "@/domain/errors";
import { InvitationModel } from "@/domain/models";
import { Invitation } from "@/domain/useCases";

export class RemoteInvitation implements Invitation {
    constructor(
        private readonly httpClient: HttpClient<InvitationModel>,
        private readonly url: string = "accept-invitation/confirm-password"
    ) {}

    async confirm_password(params: any): Promise<InvitationModel> {
        console.log("params",params)
        const response = await this.httpClient.request({
            method: "post",
            url: `${this.url}?token=${params.token}`,
            body: params,
        });

        switch (response.statusCode) {  
            case HttpStatusCode.ok:
                if (response.body) {
                    return response.body;
                } else {
                    throw new UnexpectedError(HttpStatusCode.badRequest);
                }

            case HttpStatusCode.unauthorized:
                throw new InvalidCredentialsErros(response.statusCode);

            default:
                throw new UnexpectedError(response.statusCode);
        }
    }
}
