import styled from "styled-components";

export const Container = styled.div`
  background-color: #6f3e98;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

export const Content = styled.div`
  min-height: calc(100vh - 83px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 20px;
`;

export const Logo = styled.img`
  margin-top: 6vh;
`;

export const Footer = styled.footer`
  text-align: center;
  background-color: #6f3e98;
  margin: 0;
  padding: 0;
`;
