import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid-pro";
import Table from "@/presentation/components/ActionTable";
import imgEditar from "@/presentation/assets/icone/editar.svg";
import imgExcluir from "@/presentation/assets/icone/lixeira.svg";
import { useUserContext } from "@/main/hooks/userADM";
import { useModal } from "@/main/contexts/modal";
import { Container } from "./styles";
import { Loader } from "@/presentation/components/Loader";

const profileMap: { [key: number]: string } = {
    1: "MASTER",
    2: "CLIENT",
    3: "TEST - CLIENT",
    4: "FAC",
    5: "COMERCIAL",
    6: "OPERACIONAL",
    7: "ATENDIMENTO (EXTERNO)",
};

export function TableEditUsersADM() {
    const { users, loading, setOpenPopupEdit, setUserEdit, handleUserDelete } =
        useUserContext();
    const { setState, state } = useModal();
    console.log(users);

    function getUser(id: number) {
        const findUser = users.find((u) => u.id === id);
        console.log("find", findUser);
        setUserEdit(findUser);
        setOpenPopupEdit(true);
    }

    const dados = users.map((user) => ({
        id: user.id,
        name: user.name,
        cpf: user.document || "N/A",
        email: user.email,
        profile: profileMap[user.access_polices_id] || "N/A",
        edit: "",
        delete: "",
    }));

    const columns: GridColumns = [
        {
            field: "name",
            headerName: "Nome",
            width: 300,
        },
        {
            field: "cpf",
            headerName: "CPF",
            width: 200,
        },
        {
            field: "email",
            headerName: "E-mail",
            width: 200,
        },
        {
            field: "profile",
            headerName: "Perfil",
            width: 170,
        },
        {
            field: "edit",
            headerName: "Editar",
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    <button
                        onClick={() => {
                            getUser(row.id);
                        }}
                    >
                        <img src={imgEditar} alt="Editar" />
                    </button>
                );
            },
        },
        {
            field: "delete",
            headerName: "Excluir",
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    <button
                        onClick={() => {
                            setState({
                                ...state,
                                alert: {
                                    active: true,
                                    onclick: () => handleUserDelete(row.id),
                                    text: "Tem certeza que deseja excluir esse usuário?",
                                },
                            });
                        }}
                    >
                        <img src={imgExcluir} alt="Excluir" />
                    </button>
                );
            },
        },
    ];

    if (loading) {
        return <Loader />;
    }

    return (
        <Container>
            <Table
                rows={dados}
                columns={columns}
                nameCSV="usuarios_adm"
                header={false}
            />
        </Container>
    );
}
