import { useHistory } from "react-router-dom";

import { ContainerSucesso } from "./styles";

import { Container as Button } from "@/presentation/components/Button";

import img from "@/presentation/assets/icone/sucesso.svg";
import logo from "@/presentation/assets/icone/logo-yetz-promo.svg";

export function Sucesso() {
    const history = useHistory();
    return (
        <ContainerSucesso>
            <img src={logo} alt="" className="logo" />
            <div className="conteudo">
                <img src={img} alt="" />
                <h1>
                    senha criada<br></br> COM SUCESSO!
                </h1>
                <Button type="button" onClick={() => history.push("/")}>
                    Fazer login
                </Button>
            </div>
        </ContainerSucesso>
    );
}
