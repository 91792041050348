import styled from "styled-components";
import { Container as Button } from "@/presentation/components/Button";
import imgsetaCima from "@/presentation/assets/icone/seta-top.svg";

export const Container = styled.div`
    background-color: #6f3e98;
    height: calc(100vh - 82px);
    width: 100%;
    padding-top: 50px;
    .logo {
        display: block;
        margin: auto;
    }
    h1 {
        font-size: 16px;
        font-weight: 900;
        line-height: 19.2px;
        text-align: center;
        color: #fff;
        margin: 50px 0 30px 0;
    }
    form {
        width: 327px;
        margin: auto;
        button {
            margin-top: 30px;
        }
    }
`;

export const Text = styled.div`
    p {
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        text-align: center;
        color: #fff;
        margin: 30px 0;
        strong {
            font-weight: 800;
        }
    }
    span {
        font-size: 12px;
        font-weight: 400;
        line-height: 21.6px;
        color: #fff;
    }
`;

export const ContainerValidacao = styled.div`
    background-color: #6f3e98;
    height: calc(100vh - 82px);
    width: 100%;
    padding-top: 50px;
    .logo {
        display: block;
        margin: auto;
    }
    .conteudo {
        width: 347px;
        margin: auto;
        h3 {
            font-size: 20px;
            font-weight: 900;
            line-height: 24px;
            text-align: center;
            color: #fff;
            text-transform: uppercase;
            margin: 50px 0 30px 0;
        }
        p {
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            text-align: center;
            color: #fff;
            strong {
                font-weight: 700;
            }
        }
        .campoEmail {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #e3e3e3;
            width: 327px;
            height: 40px;
            border-radius: 100px;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            text-align: center;
            color: #5e5f5f;
            margin: 30px 0;
        }
        .container-inputs {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            align-items: center;
            justify-content: space-between;
            width: 343px;
            position: relative;
            margin: 40px 0;
            &.error {
                input {
                    border: 2px solid #ff0000;
                    transition: all 0.5s;
                }
                .container-erro {
                    display: flex;
                    transition: all 0.5s;
                }
            }

            .container-erro {
                grid-column: span 6;
                justify-content: center;
                display: none;
                align-items: center;
                margin-top: 13px;
                transition: all 0.5s;
                img {
                    margin-bottom: 0px;
                    margin-right: 8px;
                }
                h5 {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: normal;
                    text-align: center;
                    color: #fff;
                    white-space: nowrap;
                    margin: 0px 5px;
                }
            }
            input {
                background: #ffffff;
                border: 1px solid #9cabb8;
                border-radius: 4px;
                width: 51px;
                height: 55px;

                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 30px;

                color: #000000;
                text-align: center;
                transition: all 0.5s;
            }
        }
        .timer {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            margin-bottom: 40px;
        }
    }
`;

export const EscolhaEnvio = styled.div`
    padding-top: 17px;
    width: auto;
    .container-btn {
        position: relative;
        cursor: pointer;
        margin-bottom: 20px;
        &.disabled {
            opacity: 0;
            cursor: not-allowed;
            transition: opacity 0.3s ease-in-out 0s;
            pointer-events: none;
            button {
                cursor: none;
                pointer-events: none;
            }
        }
        &.active {
            &:before {
                transform: translateY(-50%) rotateX(-190deg);
                transition: all 0.5s;
            }
        }
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            right: 0px;
            transform: translateY(-50%) rotateX(0deg);
            background-color: #fff;
            -webkit-mask: url(${imgsetaCima}) no-repeat;
            width: 13px;
            height: 9px;
            z-index: 20;
            transition: all 0.5s;
        }
        button {
            display: flex;
            align-items: center;
            background-color: transparent;
            border: none;
            width: 240px;
            cursor: pointer;
            .container-img {
                pointer-events: none;
                img {
                    margin: 0px;
                }
            }

            .container-text {
                text-align: left;
                margin-left: 8px;
                pointer-events: none;
                h3 {
                    font-style: normal;
                    font-weight: 800;
                    font-size: 12px;
                    line-height: 124.5%;
                    color: #fff;
                    margin: 0px;
                    text-align: left;
                }
                p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 124.5%;
                    color: #fff;
                    margin: 0px;
                    white-space: nowrap;
                }
            }
        }
    }

    .container-envio {
        overflow: hidden;
        height: 0px;
        transition: all 0.5s;
        &.active {
            transition: all 0.5s;
            height: 120px;
        }
        /* height: 0px; */
        .container-link {
            border-radius: 100px;
            background: #efefef;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            grid-gap: 14px;
            margin-bottom: 30px;
            cursor: pointer;
            .container-img {
                img {
                    margin: 0;
                }
            }
            .container-text {
                h3 {
                    font-style: normal;
                    font-weight: 800;
                    font-size: 12px;
                    line-height: 124.5%;
                    color: #fff;
                    margin: 0px;
                }
                p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 124.5%;
                    color: #fff;
                    margin: 0px;
                    white-space: nowrap;
                }
            }
        }
    }

    .container-item {
        width: 100%;
        padding-top: 20px;
        form {
            display: grid;
            grid-template-columns: 31px 1fr 90px;
            align-items: center;
            grid-gap: 0px 5px;

            .container-input {
                position: relative;
                margin: 0;
                h3 {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 100%;
                    color: #fff;
                    margin: 0px;
                    position: absolute;
                    top: -18px;
                    text-transform: unset;
                }
            }

            input {
                &:disabled {
                    background: rgb(227, 227, 227);
                    cursor: not-allowed;
                }
            }

            img {
                margin-bottom: 0px !important;
            }

            input {
                outline: none;
            }

            ${Button} {
                width: 100% !important;
                margin-bottom: 0px !important;
            }
        }
    }

    .container-divisor {
        text-align: center;
        position: relative;
        margin: 21px 0px 15px 0px;
        &:after {
            content: "";
            display: block;
            width: calc(53% - 27px);
            height: 1px;
            background: #fff;
            position: absolute;
            top: 50%;
            right: 0px;
        }
        &:before {
            content: "";
            display: block;
            width: calc(53% - 27px);
            height: 1px;
            background: #fff;
            position: absolute;
            top: 50%;
            left: 0px;
        }
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 100%;
            text-align: center;
            color: #fff;
            margin: 0px;
        }
    }

    @media (max-width: 480px) {
        margin: 0 auto;
        width: 100%;
        .container-envio {
            &.active {
                overflow: inherit;
            }
        }
        .container-item {
            button {
                margin-top: 0px;
            }
        }
    }
`;

export const ContainerSucesso = styled.div`
    background-color: #6f3e98;
    height: calc(100vh - 82px);
    width: 100%;
    padding-top: 50px;
    .logo {
        display: block;
        margin: auto;
    }
    .conteudo {
        width: 390px;
        height: 500px;
        border-radius: 20px;
        background-color: #fff;
        margin: 50px auto auto;
        align-content: center;
        justify-items: center;
        h1 {
            font-size: 20px;
            font-weight: 900;
            line-height: 24px;
            text-align: center;
            text-transform: uppercase;
            color: #57b847;
            margin: 20px 0 40px;
        }
        button {
            width: 327px;
        }
    }
`;

export const RegrasSenha = styled.div`
    span {
        display: flex;
        gap: 5px;
        img {
            width: 10px;
        }
    }
`;
