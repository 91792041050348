import { useState } from "react";
import { CountDownMemo } from "@/presentation/components/Countdown";
import { Container as Button } from "@/presentation/components/Button";
import { FormEmail } from "./formEmail";

import { Divider, FooterHelp } from "../VerifyCodeInvite/styles";

import { ContainerValidacao, EscolhaEnvio } from "./styles";

import WhiteMessageHelpIcon from "@/presentation/assets/icone/WhiteMessageHelpIcon.svg";
import logo from "@/presentation/assets/icone/logo-yetz-promo.svg";
import imgQuestao from "@/presentation/assets/icone/interrogacao.svg";

export function Validacao({ onSubmit, email, resendEmail }: any) {
    const [c1, setC1] = useState<string>("");
    const [c2, setC2] = useState<string>("");
    const [c3, setC3] = useState<string>("");
    const [c4, setC4] = useState<string>("");
    const [c5, setC5] = useState<string>("");
    const [c6, setC6] = useState<string>("");

    const [timer, setTimer] = useState<boolean>(true);
    const [disabled, setDisabled] = useState(true);

    // const email = email ?? "vitoria@yetz.com.br";
    const emailFormatado = email.split("@")[1];

    function handlePaste(event: any) {
        event.preventDefault();
        const pasteData = event.clipboardData.getData("text");
        const filteredData = pasteData.replace(/\D/g, "");
        const inputs = event.target.form.querySelectorAll('input[type="tel"]');
        filteredData.split("").forEach((char: any, index: any) => {
            if (inputs[index]) {
                inputs[index].value = char;
                if (inputs[index + 1]) {
                    inputs[index + 1].focus();
                }
            }
        });
    }

    function handleKeyUp(event: any) {
        event.preventDefault();
        const { key } = event;

        if (key === "Backspace") {
            if (event.target.previousElementSibling) {
                event.target.previousElementSibling.focus();
            }
        } else {
            if (event.target.value.length === event.target.maxLength) {
                if (event.target.nextElementSibling) {
                    event.target.nextElementSibling.focus();
                }
            }
        }
    }

    function handleChange() {
        const d = document.getElementById("container-envio-validacao");
        if (d?.classList.contains("active")) {
            d?.classList.remove("active");
            d?.previousElementSibling?.classList.remove("active");
        } else {
            d?.classList.add("active");
            d?.previousElementSibling?.classList.add("active");
        }
    }
    async function handleSubmit() {
        if (c1 && c2 && c3 && c4 && c5 && c6) {
            const code = [c1, c2, c3, c4, c5, c6].join("");

            try {
                await onSubmit(code);
            } catch (error) {
                document
                    .querySelector(".container-inputs")
                    ?.classList.add("error");
            }
        }
    }

    async function handleChangeSubmit(code6: string) {
        if (c1 && c2 && c3 && c4 && c5 && code6) {
            const code = [c1, c2, c3, c4, c5, code6].join("");

            try {
                await onSubmit(code);
            } catch (error) {
                document
                    .querySelector(".container-inputs")
                    ?.classList.add("error");
            }
        }
    }

    return (
        <ContainerValidacao>
            <img src={logo} alt="" className="logo" />
            <div className="conteudo">
                <h3 className="title">código de verificação</h3>
                <p>
                    Para concluir seu cadastro{" "}
                    <strong>PREENCHA COM O CÓDIGO DE VERIFICAÇÃO</strong>,
                    enviado para o seu e-mail.
                </p>

                <span className="campoEmail">
                    {email.substr(0, 3) +
                        "****@" +
                        emailFormatado.substring(0, 2) +
                        "****"}
                </span>

                <div className="container-inputs">
                    <input
                        type="tel"
                        maxLength={1}
                        onKeyUp={handleKeyUp}
                        onPaste={handlePaste}
                        onChange={(e) => setC1(e.target.value)}
                    />
                    <input
                        type="tel"
                        maxLength={1}
                        onKeyUp={handleKeyUp}
                        onPaste={handlePaste}
                        onChange={(e) => setC2(e.target.value)}
                    />
                    <input
                        type="tel"
                        maxLength={1}
                        onKeyUp={handleKeyUp}
                        onPaste={handlePaste}
                        onChange={(e) => setC3(e.target.value)}
                    />
                    <input
                        type="tel"
                        maxLength={1}
                        onKeyUp={handleKeyUp}
                        onPaste={handlePaste}
                        onChange={(e) => setC4(e.target.value)}
                    />
                    <input
                        type="tel"
                        maxLength={1}
                        onKeyUp={handleKeyUp}
                        onPaste={handlePaste}
                        onChange={(e) => setC5(e.target.value)}
                    />
                    <input
                        type="tel"
                        maxLength={1}
                        onKeyUp={handleKeyUp}
                        onPaste={handlePaste}
                        onChange={(e) => {
                            setC6(e.target.value);
                            handleChangeSubmit(e.target.value);
                        }}
                    />
                    <div className="container-erro">
                        <h5>Código Inválido</h5>
                    </div>
                </div>

                {timer ? (
                    <CountDownMemo
                        setDisabled={setDisabled}
                        setTimer={setTimer}
                        cor="branco"
                        tempo={60000}
                    />
                ) : null}

                <Button
                    data-testid="button"
                    type="button"
                    mb="30px"
                    // disabled={!props.isValid}
                    // isLoading={props.isSubmitting}
                    spiner="BallTriangle"
                    spinerColor="primary"
                    onClick={handleSubmit}
                >
                    Acessar
                </Button>

                <EscolhaEnvio>
                    <div
                        className={`container-btn ${
                            disabled ? "disabled" : ""
                        } `}
                        onClick={handleChange}
                    >
                        <button>
                            <div className="container-img">
                                <img src={imgQuestao} alt="" />
                            </div>
                            <div className="container-text">
                                <h3>Clique aqui</h3>
                                <p>(se não receber o código por e-mail)</p>
                            </div>
                        </button>
                    </div>
                    <div
                        className="container-envio"
                        id="container-envio-validacao"
                    >
                        <div className="container-item">
                            <FormEmail
                                setDisabled={setDisabled}
                                setTimer={setTimer}
                                sendEmail={resendEmail}
                                email={
                                    email.substr(0, 3) +
                                    "****@" +
                                    emailFormatado.substring(0, 2) +
                                    "****"
                                }
                                fecha={handleChange}
                            />
                        </div>
                        <Divider />
                        <FooterHelp>
                            <img
                                src={WhiteMessageHelpIcon}
                                alt="Help Message Icon"
                            />
                            PRECISA DE AJUDA?
                        </FooterHelp>
                    </div>
                </EscolhaEnvio>
            </div>
        </ContainerValidacao>
    );
}
