import styled from "styled-components";

interface CodeInputProps {
  hasError?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  font-family: Lato;
`;

export const Title = styled.h2`
  margin: 0;
  margin-top: 10vh;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: Lato;
`;

export const Subtitle = styled.p`
  margin: 0;
  margin-top: 30px;
  font-size: 14px;
  text-align: center;
  font-family: Lato;
`;

export const PhoneMask = styled.div`
  font-family: Lato;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px 20px;
  background: #e3e3e3;
  color: #5e5f5f;
  border-radius: 100px;
  width: 327px;
  height: 40px;
  text-align: center;
  font-weight: 700;
`;

export const CodeContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

export const CodeInput = styled.input<CodeInputProps>`
  font-family: Lato;
  width: 51px;
  height: 51px;
  margin: 0 2px;
  text-align: center;
  font-size: 16px;
  border-radius: 4px;
  border: ${({ hasError }) => (hasError ? "2px solid #ff4444" : "none")};
  color: #5e5f5f;
  outline: none;
  font-weight: 700;
`;

export const ErrorMessage = styled.p`
  margin: 0;
  font-size: 14px;
  color: #fff;
  font-family: Lato;
`;

export const Timer = styled.span`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 14px;
  gap: 5px;
  margin-top: 20px;
  font-family: Lato;
`;

export const Button = styled.button`
  width: 327px;
  height: 40px;
  background-color: #f17e21;
  color: #fff;
  border: none;
  border-radius: 100px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  margin-top: 10px;
  font-family: Lato;
  transition: background-color 0.3s ease;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

export const ButtonSendAgain = styled.button`
  width: 98px;
  height: 40px;
  background-color: #f17e21;
  color: #fff;
  border: none;
  border-radius: 100px;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  font-family: Lato;
  transition: background-color 0.3s ease;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

export const AdditionalSection = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: Lato;
`;

export const AdditionalTitle = styled.p`
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  margin: 8px 0;
  display: flex;
  align-items: center;
  user-select: none;
  font-family: Lato;

  img:first-of-type {
    margin-right: 8px;
  }

  .text-container {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  span.click-here {
    font-weight: 800;
  }

  span.info {
    font-weight: 400;
  }

  img:last-of-type {
    margin-left: 30px;
    margin-right: 8px;
  }
`;

export const AdditionalContent = styled.div<{ isExpanded: boolean }>`
  max-height: ${({ isExpanded }) => (isExpanded ? "500px" : "0")};
  overflow: hidden;
  transition: max-height 0.5s ease;
`;

export const AdditionalWhatsAppContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 8px;
  margin-bottom: 16px;
  margin-top: 35px;
  font-family: Lato;

  .text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
  }

  img {
    margin-right: 8px;
    margin-bottom: 6px;
  }
`;

export const AdditionalPhoneMask = styled.div`
  font-family: Lato;
  flex: 1;
  padding: 12px 25px;
  background: #e3e3e3;
  color: #5e5f5f;
  border-radius: 100px;
  width: 180px;
  text-align: start;
  font-size: 13px;
  font-weight: 600;
  height: 40px;
`;

export const Divider = styled.hr`
  margin: 20px auto;
  width: 80%;
  border: none;
  border-top: 1px solid #fff;
`;

export const FooterHelp = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-weight: 900;
  margin-bottom: 20px;
  font-size: 12px;
  cursor: pointer;
  font-family: Lato;

  img {
    margin-right: 6px;
    margin-top: 2px;
  }
`;
