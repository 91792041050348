import { HttpClient, HttpStatusCode } from "@/data/protocols/http";
import { InvalidCredentialsErros, UnexpectedError } from "@/domain/errors";
import { InvitationEmail } from "@/domain/useCases";
import { EmailValidationModel } from "@/domain/models";

export class RemoteEmail implements InvitationEmail {
    constructor(
        private readonly httpClientEmail: HttpClient<EmailValidationModel>,
    ) {}

    async email_validation(params: any): Promise<EmailValidationModel> {
        const url = "accept-invitation/email-validation";
        const response = await this.httpClientEmail.request({
            method: "post",
            url: `${url}?token=${params.token}`,
            body: params,
        });

        switch (response.statusCode) {  
            case HttpStatusCode.ok:
                if (response.body) {
                    return response.body;
                } else {
                    throw new UnexpectedError(HttpStatusCode.badRequest);
                }

            case HttpStatusCode.unauthorized:
                throw new InvalidCredentialsErros(response.statusCode);

            default:
                throw new UnexpectedError(response.statusCode);
        }
    }

    async resend_email_validation(params: any): Promise<EmailValidationModel> {
        const url = "accept-invitation/resend-email-validation";
        const response = await this.httpClientEmail.request({
            method: "get",
            url: `${url}?token=${params.token}`,
        });

        switch (response.statusCode) {  
            case HttpStatusCode.ok:
                if (response.body) {
                    return response.body;
                } else {
                    throw new UnexpectedError(HttpStatusCode.badRequest);
                }

            case HttpStatusCode.unauthorized:
                throw new InvalidCredentialsErros(response.statusCode);

            default:
                throw new UnexpectedError(response.statusCode);
        }
    }
}
