import { ErrorMessage, Form, Formik, Field } from "formik";
import { validationEmail, valuesEmail } from "./config";
import { Container as Button } from "@/presentation/components/Button";
import imgEmail from "@/presentation/assets/icone/email.svg";

export function FormEmail(props: any) {
    const { setTimer, setDisabled, sendEmail, email, fecha } = props;
    // const [loading, setLoading] = useState(false);
    return (
        <Formik
            validateOnMount
            enableReinitialize
            initialValues={valuesEmail}
            validationSchema={validationEmail}
            onSubmit={async () => {
                await sendEmail();
                fecha();
                setTimer(true);
                setDisabled(true);
            }}
        >
            {() => {
                return (
                    <Form>
                        <img src={imgEmail} alt="" />

                        <div className="container-input">
                            <h3>Receber por E-mail</h3>
                            <Field
                                name="sms"
                                type="text"
                                value={email}
                                disabled={true}
                            />
                            <ErrorMessage
                                name="text"
                                data-testid="error"
                                component="span"
                            />
                        </div>

                        <Button type="submit">Reenviar</Button>
                    </Form>
                );
            }}
        </Formik>
    );
}
