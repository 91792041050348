import React from "react";
import WhiteLogo from "@/presentation/assets/icone/WhiteLogo.svg";
import { Container, Content, Logo, Footer } from "./styles";

interface GenericLayoutInviteProps {
  title: string;
  children: React.ReactNode;
}

export function GenericLayoutInvite({ children }: GenericLayoutInviteProps) {
  return (
    <Container>
      <Content>
        <Logo src={WhiteLogo} alt="Logo" />
        {children}
      </Content>
      <Footer />
    </Container>
  );
}
