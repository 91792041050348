import { useState } from "react";
import { useLocation } from "react-router-dom";
import { AxiosHttpClient } from "@/infra/http/axiosClient/axiosClient";

import { RemoteAcceptInvite } from "@/data/useCases/acceptInvite/RemoteAcceptInvite";
import { RemoteVerifyCodeInvite } from "@/data/useCases/verifyCodeInvite/RemoteVerifyCodeInvite";
import { RemoteInvitation } from "@/data/useCases/accept-invitation";
import { RemoteEmail } from "@/data/useCases/accept-invitation/remoteEmail";

import { GenericLayoutInvite } from "@/presentation/components/GenericLayoutInvite";
import { AcceptInviteForm } from "@/presentation/components/AcceptInviteForm";
import { VerifyCodeInvite } from "@/presentation/components/VerifyCodeInvite";

import { CriaSenha } from "@/presentation/components/CriaSenha";
import { Validacao } from "@/presentation/components/CriaSenha/validacao";
import { Sucesso } from "@/presentation/components/CriaSenha/sucesso";

export function PageAcceptInvite() {
  const [isVerified, setIsVerified] = useState("acceptInvite");
  const [resultVerificationCode, setResultVerificationCode] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [cpf, setCpf] = useState("");
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token") || "";
  const axios = new AxiosHttpClient();

  const remoteAcceptInvite = new RemoteAcceptInvite(axios);
  const remoteVerifyCodeInvite = new RemoteVerifyCodeInvite(axios);
  const remoteInvitation = new RemoteInvitation(axios);
  const remoteEmailInvitation = new RemoteEmail(axios);

  const handleAcceptInvite = async (cpfValue) => {
    if (!token) {
      throw new Error("Token ausente.");
    }
    setCpf(cpfValue);
    const result = await remoteAcceptInvite.execute({ token, cpf: cpfValue });
    console.log("AcceptInvite result:", result);

    if (result.mobile) {
      setPhoneNumber(result.mobile);
    }

    setIsVerified("verificationCode");
  };

  const handleResendAcceptInvite = async () => {
    if (!token) {
      throw new Error("Token ausente.");
    }
    if (!cpf) {
      throw new Error("CPF não informado.");
    }
    const result = await remoteAcceptInvite.execute({ token, cpf });
    console.log("Resend AcceptInvite result:", result);

    if (result.mobile) {
      setPhoneNumber(result.mobile);
    }
    setIsVerified("verificationCode");
  };

  const handleVerificationCode = async (confirmationToken) => {
    if (!token) {
      throw new Error("Token ausente.");
    }
    const result = await remoteVerifyCodeInvite.execute({
      token,
      confirmation_token: confirmationToken,
    });
    console.log("VerificationCode result:", result);
    setResultVerificationCode(result);
    setIsVerified("confirmPassword");
  };

  const handleConfirmPassword = async (values) => {
    if (!token) {
      throw new Error("Token ausente.");
    }
    console.log("entrou confirm");
    const response = await remoteInvitation.confirm_password({
      token,
      password: values.password,
      confirm_password: values.confirm_password,
    });
    console.log("ConfirmPassword result:", response);
    setIsVerified("validCode");
  };

  const handleValidCode = async (code) => {
    if (!token) {
      throw new Error("Token ausente.");
    }
    const response = await remoteEmailInvitation.email_validation({
      token,
      confirmation_token: code,
    });
    console.log("ValidCode result:", response);
    setIsVerified("success");
  };

  const handleResendEmail = async () => {
    if (!token) {
      throw new Error("Token ausente.");
    }
    const response = await remoteEmailInvitation.resend_email_validation({
      token,
    });
    console.log("ResendEmail result:", response);
  };

  return (
    <>
      {(isVerified === "acceptInvite" || isVerified === "verificationCode") ? (
        <GenericLayoutInvite>
          {isVerified === "verificationCode" ? (
            <VerifyCodeInvite
              phone={phoneNumber}
              onSubmit={handleVerificationCode}
              onResend={handleResendAcceptInvite}
            />
          ) : (
            <AcceptInviteForm onSubmit={handleAcceptInvite} />
          )}
        </GenericLayoutInvite>
      ) : isVerified === "confirmPassword" ? (
        <CriaSenha onSubmit={handleConfirmPassword} result={resultVerificationCode} />
      ) : isVerified === "validCode" ? (
        <Validacao
          onSubmit={handleValidCode}
          email={resultVerificationCode.email}
          resendEmail={handleResendEmail}
        />
      ) : (
        <Sucesso />
      )}
    </>
  );
}
