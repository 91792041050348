import { AcceptInvite } from "@/domain/useCases/acceptInvite";
import { HttpClient, HttpStatusCode } from "@/data/protocols/http";
import { UnexpectedError } from "@/domain/errors";

export class RemoteAcceptInvite implements AcceptInvite {
  private readonly baseUrl: string = "accept-invitation/identification";

  constructor(private readonly httpClient: HttpClient<AcceptInvite.Result>) {}

  async execute(data: AcceptInvite.Params): Promise<AcceptInvite.Result> {
    const url = `${this.baseUrl}?token=${data.token}`;
    const body = { cpf: data.cpf };

    const response = await this.httpClient.request({
      url,
      method: "post",
      body,
    });

    if (
      response.statusCode === HttpStatusCode.created ||
      response.statusCode === HttpStatusCode.ok
    ) {
      if (response.body) {
        return response.body;
      }
    }

    throw new UnexpectedError();
  }
}
