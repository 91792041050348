import { useEffect, useRef, useState } from "react";
import WhiteClockIcon from "@/presentation/assets/icone/WhiteClockIcon.svg";
import WhiteArrowDownIcon from "@/presentation/assets/icone/WhiteArrowDownIcon.svg";
import WhiteArrowUpIcon from "@/presentation/assets/icone/WhiteArrowUpIcon.svg";
import WhiteHelpIcon from "@/presentation/assets/icone/WhiteHelpIcon.svg";
import WhiteMessageHelpIcon from "@/presentation/assets/icone/WhiteMessageHelpIcon.svg";
import WhiteWhatsAppIcon from "@/presentation/assets/icone/WhiteWhatsAppIcon.svg";

import {
  Container,
  Title,
  Subtitle,
  PhoneMask,
  CodeContainer,
  CodeInput,
  Timer,
  Button,
  ErrorMessage,
  AdditionalSection,
  AdditionalTitle,
  AdditionalContent,
  AdditionalWhatsAppContainer,
  AdditionalPhoneMask,
  Divider,
  FooterHelp,
  ButtonSendAgain
} from "./styles";

interface VerifyCodeInviteProps {
  onSubmit?: (code: string) => Promise<void>;
  onResend?: () => Promise<void>;
  phone?: string;
}

const DEFAULT_PHONE = "(35) 9 9999-6640";

export function VerifyCodeInvite({ onSubmit, onResend, phone = DEFAULT_PHONE }: VerifyCodeInviteProps) {
  const [digits, setDigits] = useState<string[]>(["", "", "", "", "", ""]);
  const [timeLeft, setTimeLeft] = useState<number>(60);
  const [hasError, setHasError] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isResending, setIsResending] = useState<boolean>(false);

  const inputsRef = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    inputsRef.current[0]?.focus();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(prev => (prev > 0 ? prev - 1 : 0));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (digits.every(digit => digit === "")) {
      setHasError(false);
    }
  }, [digits]);

  const maskPhone = (phoneNumber: string, type: number): string => {
    const finalPhone = phoneNumber || DEFAULT_PHONE;
    const digitsOnly = finalPhone.replace(/\D/g, "");
    if (digitsOnly.length < 10) return finalPhone;
    const ddd = digitsOnly.slice(0, 2);
    const last = digitsOnly.slice(-4);
    return type === 1 ? `(${ddd}) X XXXX-${last}` : `(${ddd}) * ****-${last}`;
  };

  const handleSubmit = async (code: string): Promise<void> => {
    if (onSubmit) {
      try {
        setIsSubmitting(true);
        await onSubmit(code);
        setHasError(false);
      } catch (error) {
        setHasError(true);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleChange = (value: string, index: number): void => {
    if (!/^\d*$/.test(value)) return;
    const newDigits = [...digits];
    newDigits[index] = value.slice(-1);
    setDigits(newDigits);
    if (value !== "") {
      if (index < digits.length - 1) {
        inputsRef.current[index + 1]?.focus();
      } else {
        const code = newDigits.join("");
        handleSubmit(code);
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number): void => {
    if (e.key === "Backspace" && digits[index] === "" && index > 0) {
      e.preventDefault();
      inputsRef.current[index - 1]?.focus();
      const newDigits = [...digits];
      newDigits[index - 1] = "";
      setDigits(newDigits);
    }
  };

  const toggleExpand = (): void => {
    setIsExpanded(prev => !prev);
  };

  const handleResendButton = async (): Promise<void> => {
    if (onResend) {
      try {
        setIsResending(true);
        await onResend();
      } catch (error) {
        console.error("Error in onResend:", error);
      } finally {
        setIsResending(false);
      }
    }
    setDigits(["", "", "", "", "", ""]);
    setTimeLeft(60);
    setIsExpanded(false);
    inputsRef.current[0]?.focus();
  };

  return (
    <Container>
      <Title>CÓDIGO DE VERIFICAÇÃO</Title>
      <Subtitle>
        PREENCHA COM O CÓDIGO DE VERIFICAÇÃO <br />
        enviado para o seu WhatsApp.
      </Subtitle>
      <PhoneMask>{maskPhone(phone || DEFAULT_PHONE, 1)}</PhoneMask>
      <CodeContainer>
        {digits.map((digit, i: number) => (
          <CodeInput
            key={i}
            maxLength={1}
            value={digit}
            hasError={hasError}
            ref={(ref) => (inputsRef.current[i] = ref)}
            onChange={(e) => handleChange(e.target.value, i)}
            onKeyDown={(e) => handleKeyDown(e, i)}
          />
        ))}
      </CodeContainer>
      {hasError && <ErrorMessage>Código inválido</ErrorMessage>}
      <Timer>
        <img src={WhiteClockIcon} alt="Clock Icon" />
        00:00:{timeLeft.toString().padStart(2, "0")}
      </Timer>
      <Button onClick={() => handleSubmit(digits.join(""))} disabled={isSubmitting}>
        {isSubmitting ? "Verificando..." : "Verificar"}
      </Button>
      {(hasError || timeLeft === 0) && (
        <AdditionalSection>
          <AdditionalTitle onClick={toggleExpand}>
            <img src={WhiteHelpIcon} alt="Help Icon" />
            <div className="text-container">
              <span className="click-here">CLIQUE AQUI</span>
              <span className="info">(se não receber o código pelo WhatsApp)</span>
            </div>
            {isExpanded ? (
              <img src={WhiteArrowUpIcon} alt="Arrow Up Icon" />
            ) : (
              <img src={WhiteArrowDownIcon} alt="Arrow Down Icon" />
            )}
          </AdditionalTitle>
          <AdditionalContent isExpanded={isExpanded}>
            <AdditionalWhatsAppContainer>
              <img src={WhiteWhatsAppIcon} alt="WhatsApp Icon" />
              <div className="text-container">
                <span className="title">Receber por WhatsApp</span>
                <AdditionalPhoneMask>
                  {maskPhone(phone || DEFAULT_PHONE, 2)}
                </AdditionalPhoneMask>
              </div>
              <ButtonSendAgain disabled={isResending} onClick={handleResendButton}>
                {isResending ? "Reenviando..." : "Reenviar"}
              </ButtonSendAgain>
            </AdditionalWhatsAppContainer>
            <Divider />
            <FooterHelp>
              <img src={WhiteMessageHelpIcon} alt="Help Message Icon" />
              PRECISA DE AJUDA?
            </FooterHelp>
          </AdditionalContent>
        </AdditionalSection>
      )}
    </Container>
  );
}
