import styled from "styled-components";

export const Container = styled.div`
  background-color: #6f3e98;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: Lato;
`;

export const Content = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 20px;
  font-family: Lato;
`;

export const Title = styled.h2`
  margin-top: 10vh;
  color: #fff;
  text-align: center;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%;
  text-transform: uppercase;
  margin-bottom: 20px;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Lato;
`;

export const Input = styled.input`
  margin-top: 4vh;
  display: flex;
  width: 327px;
  height: 40px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  border: none;
  border-radius: 100px;
  padding: 0 8px;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: rgba(0, 0, 0, 0.50);
  background-color: #fff;
  padding-left: 20px;
`;

export const Button = styled.button`
  margin-top: 10px;
  display: flex;
  width: 327px;
  height: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border: none;
  border-radius: 100px;
  background-color: #f17e21;
  color: #fff;
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

export const Message = styled.p`
  color: #fff;
  margin-top: 10px;
  font-family: Lato;
`;

export const Footer = styled.footer`
  height: 82px;
  text-align: center;
  background-color: #6f3e98;
  margin: 0;
  padding: 0;
  font-family: Lato;
`;
