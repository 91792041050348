import * as Yup from "yup";

export const valuesEmail = {
  email: "",
};

export const validationEmail = Yup.object().shape({
  email: Yup.string(),
});


export type CriaSenhaParams = {
    cpf: string,
    nome: string,
    password: string,
    confirm_password: string,
}

export const login: CriaSenhaParams = {
    cpf: "",
    nome: "",
    password: "",
    confirm_password: "",
};

export const validationLogin = Yup.object().shape({
    // cpf: Yup.string()
    //     .required("Campo obrigatório")
    //     .test('validar-cpf', 'CPF inválido', valor => valor ? validarCPF(valor) : false),
    // password: Yup.string()
    //     .min(6, "Senha deve ter pelo menos 6 caracteres")
    //     .required("Senha é obrigatória"),
});
