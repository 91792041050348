import React, { createContext, useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { UserADMModel } from "@/domain/models";
import { ListUsers } from "@/domain/useCases/listADMUser";
import { UserDelete } from "@/domain/useCases/deleteUser";

interface UserContextType {
    users: UserADMModel[];
    loading: boolean;
    loadUsers: (page?: number) => Promise<void>;
    openPopupEdit: boolean;
    setOpenPopupEdit: any;
    handleUserDelete: (id: number) => Promise<void>;
    userEdit: any;
    setUserEdit: any;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const useUserContext = (): UserContextType => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error(
            "useUserContext deve ser usado dentro de um UserProvider"
        );
    }
    return context;
};

interface UserProviderProps {
    listUsers: ListUsers;
    children: React.ReactNode;
    deleteUser: UserDelete;
}

export const UseUserProvider: React.FC<UserProviderProps> = ({
    listUsers,
    children,
    deleteUser,
}) => {
    const [users, setUsers] = useState<UserADMModel[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [openPopupEdit, setOpenPopupEdit] = useState(false);
    const [userEdit, setUserEdit] = useState(false);

    const loadUsers = async (page?: number) => {
        setLoading(true);
        try {
            const data = await listUsers.loadAll(page);
            setUsers(data);
        } catch (error) {
            console.error("Erro ao carregar usuários:", error);
        } finally {
            setLoading(false);
        }
    };

    async function handleUserDelete(id: number) {
        setLoading(true);
        try {
            await deleteUser.deleteUser({ id: id });
            await loadUsers();
            toast.success("Usuário deletado.");
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                console.log("Carregando usuários na montagem do componente.");
                await loadUsers();
                console.log("Usuários carregados com sucesso.");
            } catch (error) {
                console.error("Erro ao carregar usuários:", error);
            }
        };

        fetchUsers();
    }, []);

    return (
        <UserContext.Provider
            value={{
                users,
                loading,
                loadUsers,
                openPopupEdit,
                setOpenPopupEdit,
                handleUserDelete,
                userEdit,
                setUserEdit,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};
