import { VerifyCodeInvite } from "@/domain/useCases/verifyCodeInvite";
import { HttpClient, HttpStatusCode } from "@/data/protocols/http";
import { UnexpectedError } from "@/domain/errors";

export class RemoteVerifyCodeInvite implements VerifyCodeInvite {
  private readonly baseUrl: string = "accept-invitation/phone-validation";

  constructor(private readonly httpClient: HttpClient<VerifyCodeInvite.Result>) {}

  async execute(data: VerifyCodeInvite.Params): Promise<VerifyCodeInvite.Result> {
    const url = `${this.baseUrl}?token=${data.token}`;
    const body = { confirmation_token: data.confirmation_token };

    const response = await this.httpClient.request({
      url,
      method: "post",
      body,
    });

    if (response.statusCode === HttpStatusCode.ok && response.body) {
      return response.body;
    }

    throw new UnexpectedError();
  }
}
