import { useState } from "react";
import { Formik, Form, ErrorMessage, FormikProps, Field } from "formik";
import { toast } from "react-toastify";
import { Container as Button } from "@/presentation/components/Button";
import { login, validationLogin, CriaSenhaParams } from "./config";

import { Container, Text, RegrasSenha } from "./styles";

import logo from "@/presentation/assets/icone/logo-yetz-promo.svg";
import check from "@/presentation/assets/icone/check-report.svg";
import erro from "@/presentation/assets/icone/erro-report.svg";

export function CriaSenha({ onSubmit, result }: any) {
    const [tamanhoMinimo, setTamanhoMinimo] = useState(false);
    const [maiuscula, setMaiuscula] = useState(false);
    const [minuscula, setMinuscula] = useState(false);
    const [numeros, setNumeros] = useState(false);
    const [caracteresEspeciais, setCaracteresEspeciais] = useState(false);
    const [caracteresRepetidos, setCaracteresRepetidos] = useState(false);

    const [loading, setLoading] = useState(false);

    function validaSenha(senha: any) {
        const maiuscula = /^(?=.*[A-Z])/;
        const minuscula = /^(?=.*[a-z])/;
        const numeros = /^(?=.*[0-9])/;
        const caracteresEspeciais = /^(?=.*[!@#$%^&*])/;
        const caracteresRepetidos = /^(?=.*([a-z])\1\1\1)/;

        if (senha.length >= 8) {
            setTamanhoMinimo(true);
        } else {
            setTamanhoMinimo(false);
        }
        if (maiuscula.test(senha)) {
            setMaiuscula(true);
        } else {
            setMaiuscula(false);
        }
        if (minuscula.test(senha)) {
            setMinuscula(true);
        } else {
            setMinuscula(false);
        }
        if (numeros.test(senha)) {
            setNumeros(true);
        } else {
            setNumeros(false);
        }
        if (caracteresEspeciais.test(senha)) {
            setCaracteresEspeciais(true);
        } else {
            setCaracteresEspeciais(false);
        }
        if (!caracteresRepetidos.test(senha)) {
            setCaracteresRepetidos(true);
        } else {
            setCaracteresRepetidos(false);
        }
    }

    const handleSubmit = (values: CriaSenhaParams) => {
        if (values.password === values.confirm_password) {
            const adjustedValues = {
                ...values,
            };
            onSubmit(adjustedValues, result.email);
        } else {
            toast.error("Senhas não coincidem");
        }
    };

    return (
        <>
            <Container>
                <img src={logo} alt="" className="logo" />
                <h1>CRIE SUA SENHA</h1>
                <Formik
                    validateOnMount
                    enableReinitialize
                    initialValues={login}
                    validationSchema={validationLogin}
                    onSubmit={handleSubmit}
                >
                    {(props: FormikProps<CriaSenhaParams>) => {
                        return (
                            <Form data-testid="form-login">
                                <div className="container-input">
                                    <Field
                                        type="text"
                                        placeholder="CPF"
                                        name="cpf"
                                        data-testid="cpf"
                                        value={result.cpf}
                                        disabled
                                    />
                                </div>
                                <ErrorMessage
                                    name="cpf"
                                    data-testid="error"
                                    component="span"
                                />
                                <div className="container-input">
                                    <input
                                        type="text"
                                        placeholder="Nome"
                                        name="nome"
                                        value={result.name}
                                        data-testid="nome"
                                        disabled
                                    />
                                </div>
                                <ErrorMessage
                                    name="nome"
                                    data-testid="error"
                                    component="span"
                                />

                                <div className="container-input">
                                    <input
                                        type="password"
                                        placeholder="Senha"
                                        name="password"
                                        data-testid="password"
                                        onChange={(e) => {
                                            validaSenha(e.target.value);
                                            props.setFieldValue(
                                                "password",
                                                e.target.value
                                            );
                                        }}
                                    />
                                </div>
                                <ErrorMessage
                                    name="password"
                                    data-testid="error"
                                    component="span"
                                />
                                <div className="container-input">
                                    <input
                                        type="password"
                                        placeholder="Confirmar senha"
                                        name="confirm_password"
                                        data-testid="confirm_password"
                                        onChange={(e) => {
                                            props.setFieldValue(
                                                "confirm_password",
                                                e.target.value
                                            );
                                        }}
                                    />
                                </div>
                                <ErrorMessage
                                    name="confirm_password"
                                    data-testid="error"
                                    component="span"
                                />

                                <Text>
                                    <p>
                                        <strong>Atenção!</strong>
                                        <br></br> A senha deve ter
                                        obrigatoriamente:
                                    </p>
                                    <RegrasSenha>
                                        <span>
                                            {tamanhoMinimo ? (
                                                <img src={check} alt="" />
                                            ) : (
                                                <img src={erro} alt="" />
                                            )}
                                            No mínimo 8 caracteres
                                        </span>
                                        <span>
                                            {numeros ? (
                                                <img src={check} alt="" />
                                            ) : (
                                                <img src={erro} alt="" />
                                            )}
                                            No mínimo 1 número
                                        </span>
                                        <span>
                                            {maiuscula ? (
                                                <img src={check} alt="" />
                                            ) : (
                                                <img src={erro} alt="" />
                                            )}
                                            No mínimo 1 letra maiúscula
                                        </span>
                                        <span>
                                            {minuscula ? (
                                                <img src={check} alt="" />
                                            ) : (
                                                <img src={erro} alt="" />
                                            )}
                                            No mínimo 1 letra minúscula
                                        </span>
                                        <span>
                                            {caracteresRepetidos ? (
                                                <img src={check} alt="" />
                                            ) : (
                                                <img src={erro} alt="" />
                                            )}
                                            Não repetir 4 caracteres iguais em
                                            sequência
                                        </span>
                                        <span>
                                            {caracteresEspeciais ? (
                                                <img src={check} alt="" />
                                            ) : (
                                                <img src={erro} alt="" />
                                            )}
                                            No mínimo 1 caractere especial
                                            (!@#$%^*&)
                                        </span>
                                    </RegrasSenha>
                                </Text>

                                <Button
                                    data-testid="button"
                                    type="submit"
                                    mb="30px"
                                    disabled={loading}
                                >
                                    {loading ? "Enviando..." : "Enviar"}
                                </Button>
                            </Form>
                        );
                    }}
                </Formik>
            </Container>
        </>
    );
}
